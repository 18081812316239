var Command;
(function (Command) {
    Command[Command["maric\u00F3n"] = 0] = "maric\u00F3n";
    Command[Command["maraco"] = 1] = "maraco";
    Command[Command["we\u00F3n"] = 2] = "we\u00F3n";
    Command[Command["aweonao"] = 3] = "aweonao";
    Command[Command["maraca"] = 4] = "maraca";
    Command[Command["chucha"] = 5] = "chucha";
    Command[Command["puta"] = 6] = "puta";
    Command[Command["pichula"] = 7] = "pichula";
    Command[Command["tula"] = 8] = "tula";
    Command[Command["pico"] = 9] = "pico";
    Command[Command["ctm"] = 10] = "ctm";
    Command[Command["qu\u00E9we\u00E1"] = 11] = "qu\u00E9we\u00E1";
    Command[Command["ch\u00FApala"] = 12] = "ch\u00FApala";
    Command[Command["br\u00EDgido"] = 13] = "br\u00EDgido";
    Command[Command["perkin"] = 14] = "perkin";
    Command[Command["mierda"] = 15] = "mierda";
})(Command || (Command = {}));
export default Command;
