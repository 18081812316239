var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, PropSync, Prop, Vue } from "vue-property-decorator";
let TheInputManager = class TheInputManager extends Vue {
    focusInput() {
        this.$refs.input.focus();
    }
    inputText() {
        this.$emit("enterButtonClicked");
    }
};
__decorate([
    PropSync("input", { required: true })
], TheInputManager.prototype, "syncedInput", void 0);
__decorate([
    Prop({ default: true })
], TheInputManager.prototype, "isInputDisabled", void 0);
TheInputManager = __decorate([
    Component
], TheInputManager);
export default TheInputManager;
