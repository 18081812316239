import CodeParser from "./CodeParser";
export class WasmInterpreter {
    constructor(wasmModule, ptrs) {
        this.wasmModule = wasmModule;
        this.wasmModule._add_function_pointers(...ptrs);
    }
    interpret(code) {
        this.run(this.parseCode(code));
    }
    parseCode(code) {
        return new CodeParser(code).parse();
    }
    run(commands) {
        const typedCommands = new Uint8Array(commands);
        const cmdPtr = this.wasmModule._malloc(typedCommands.length * typedCommands.BYTES_PER_ELEMENT);
        this.wasmModule.HEAPU8.set(typedCommands, cmdPtr);
        this.wasmModule._run_commands(cmdPtr, commands.length);
        this.wasmModule._free(cmdPtr);
    }
    sendInput(input) {
        const bytesToWrite = this.wasmModule.lengthBytesUTF32(input);
        const utf32Input = this.wasmModule._malloc(bytesToWrite + 4);
        this.wasmModule.stringToUTF32(input, utf32Input, bytesToWrite + 4);
        this.wasmModule._received_input(utf32Input, bytesToWrite / 4);
        this.wasmModule._free(utf32Input);
    }
    stop() {
        this.wasmModule._stop_running_commands();
    }
}
