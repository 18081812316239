var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";
import "codemirror/addon/mode/simple.js";
let CodeEditor = class CodeEditor extends Vue {
    constructor() {
        super(...arguments);
        this.code = "";
    }
    runCode() {
        this.$emit("runButtonClicked", this.code);
    }
    stopCode() {
        this.$emit("stopButtonClicked");
    }
    copyCode() {
        this.copyCodeToClipboard();
        this.$bvToast.toast("Code was copied", {
            title: "Info",
            autoHideDelay: 1000,
            noHoverPause: true,
            isStatus: true,
            variant: "info"
        });
    }
    clearCode() {
        this.editor.setValue("");
    }
    focusEditor() {
        this.editor.focus();
    }
    copyCodeToClipboard() {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(this.code);
        }
        else {
            const textArea = document.createElement("textarea");
            textArea.value = this.code;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
        }
    }
    mounted() {
        this.defineLaWeáMode();
        this.setUpCodeEditor();
    }
    defineLaWeáMode() {
        CodeMirror.defineSimpleMode("laweá", {
            start: [
                { regex: /[abcdeghiklmnopqrtuwáéíóú\s]/, token: "keyword" },
                { regex: /#.*/, token: "comment" },
                { regex: /./, token: "error" }
            ],
            meta: {
                lineComment: "#"
            }
        });
    }
    setUpCodeEditor() {
        this.editor = CodeMirror(document.getElementById("code-editor"), {
            lineNumbers: true,
            lineWrapping: true,
            inputStyle: "contenteditable",
            mode: "laweá",
            theme: "monokai"
        });
        this.editor.on("change", () => {
            this.code = this.editor.getValue();
        });
        const documentFontSize = getComputedStyle(document.documentElement).fontSize;
        document.querySelector(".CodeMirror").style.fontSize = documentFontSize;
    }
};
__decorate([
    Prop({ default: true })
], CodeEditor.prototype, "isStopButtonDisabled", void 0);
CodeEditor = __decorate([
    Component
], CodeEditor);
export default CodeEditor;
