var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from "vue-property-decorator";
import TheCodeEditor from "../TheCodeEditor/TheCodeEditor.vue";
import TheOutputManager from "../TheOutputManager/TheOutputManager.vue";
import TheInputManager from "../TheInputManager/TheInputManager.vue";
import TheErrorModal from "../TheErrorModal/TheErrorModal.vue";
import LaWeá from "../../LaWeá";
import Module from "../../LaWeá/wasm/run_commands.js";
let TheMainSection = class TheMainSection extends Vue {
    constructor() {
        super(...arguments);
        this.output = "";
        this.input = "";
        this.isStopButtonDisabled = true;
        this.isInputDisabled = true;
        this.errorMessage = "";
    }
    interpretLaWeá(code) {
        this.stopRunningLaWeáCommands();
        this.output = this.input = "";
        this.isStopButtonDisabled = false;
        try {
            this.laWeáInterpreter.interpret(code);
        }
        catch (error) {
            this.exitLaWeáInterpreter(error instanceof LaWeá.CustomError ? error.message : undefined);
        }
    }
    sendInputToLaWeáInterpreter() {
        this.laWeáInterpreter.sendInput(this.input);
    }
    stopRunningLaWeáCommands() {
        this.laWeáInterpreter.stop();
        this.isStopButtonDisabled = this.isInputDisabled = true;
    }
    exitLaWeáInterpreter(errorMessage) {
        this.isStopButtonDisabled = this.isInputDisabled = true;
        if (errorMessage && errorMessage.length > 1) {
            this.errorMessage = errorMessage;
            this.$bvModal.show("error-modal");
            throw new Error(errorMessage);
        }
    }
    async mounted() {
        const wasmModule = await Module();
        this.laWeáInterpreter = new LaWeá.WasmInterpreter(wasmModule, this.getFunctionPointers(wasmModule));
    }
    getFunctionPointers(wasmModule) {
        const appendCharToOutputPtr = wasmModule.addFunction((charPtr) => {
            this.output += wasmModule.UTF32ToString(charPtr);
        }, "vi");
        const appendNumToOutputPtr = wasmModule.addFunction((numPtr) => {
            this.output += wasmModule.UTF8ToString(numPtr);
        }, "vi");
        const isInputDisabledPtr = wasmModule.addFunction((isInputDisabled) => {
            this.isInputDisabled = Boolean(isInputDisabled);
            this.$nextTick(() => {
                if (!this.isInputDisabled) {
                    this.input = "";
                    this.$refs.inputManager.focusInput();
                }
            });
        }, "vi");
        const exitInterpreterPtr = wasmModule.addFunction((errorMessagePtr) => {
            this.exitLaWeáInterpreter(wasmModule.UTF8ToString(errorMessagePtr));
        }, "vi");
        return [appendCharToOutputPtr, appendNumToOutputPtr, isInputDisabledPtr, exitInterpreterPtr];
    }
};
TheMainSection = __decorate([
    Component({
        components: {
            TheCodeEditor,
            TheOutputManager,
            TheInputManager,
            TheErrorModal
        }
    })
], TheMainSection);
export default TheMainSection;
