import Command from "./Command";
import { InvalidCommandError, UnmatchedTulaError, MisplacedPicoError, InvalidCharacterError, TooLongCommandError, UnmatchedPichulasError } from "./errors";
export default class CodeParser {
    constructor(code) {
        this.commands = [];
        this.isMidComment = false;
        this.commandNameBuffer = "";
        this.line = 1;
        this.col = 1;
        this.loopOpenCommandsCount = 0;
        this.loopCloseCommandsCount = 0;
        this.code = code;
    }
    parse() {
        this.parseCode();
        this.checkLoopsBalance();
        return this.commands;
    }
    parseCode() {
        for (let i = 0; i <= this.code.length; i++) {
            this.parseCharacterAtIndex(i);
            this.parsedCharacterAtIndex(i);
        }
    }
    parseCharacterAtIndex(codeIndex) {
        if (this.code[codeIndex] === "#") {
            this.isMidComment = true;
        }
        if (this.isCommandBoundary(codeIndex)) {
            this.handlePotentialCommand();
        }
        else if (!this.isMidComment) {
            this.addCharacterAtIndexToCommandName(codeIndex);
        }
    }
    isCommandBoundary(codeIndex) {
        return codeIndex === this.code.length || /\s/.test(this.code[codeIndex]) || this.code[codeIndex] === "#";
    }
    handlePotentialCommand() {
        if (this.commandNameBuffer.length > 0) {
            const command = this.getCommandFromCommandName();
            this.handleLoopBalancing(command);
            this.commands.push(command);
            this.commandNameBuffer = "";
        }
    }
    getCommandFromCommandName() {
        for (let cmd = 0; cmd < 16; cmd++) {
            if (this.commandNameBuffer === Object.values(Command)[cmd]) {
                return cmd;
            }
        }
        throw new InvalidCommandError(this.commandNameBuffer, this.line, this.col - this.commandNameBuffer.length);
    }
    handleLoopBalancing(command) {
        if (command === Command.pichula) {
            this.handlePichulaCommand();
        }
        else if (command === Command.tula) {
            this.handleTulaCommand();
        }
        else if (command === Command.pico) {
            this.handlePicoCommand();
        }
    }
    handlePichulaCommand() {
        this.loopOpenCommandsCount++;
    }
    handleTulaCommand() {
        if (this.loopCloseCommandsCount === this.loopOpenCommandsCount) {
            throw new UnmatchedTulaError(this.line, this.col - this.commandNameBuffer.length);
        }
        this.loopCloseCommandsCount++;
    }
    handlePicoCommand() {
        if (this.loopOpenCommandsCount === this.loopCloseCommandsCount) {
            throw new MisplacedPicoError(this.line, this.col - this.commandNameBuffer.length);
            throw new Error("No debiste meter ese pico en la línea " +
                String(this.line) +
                ", columna " +
                String(this.col - this.commandNameBuffer.length));
        }
    }
    addCharacterAtIndexToCommandName(codeIndex) {
        this.validateCommandCharacterAtIndex(codeIndex);
        this.validateCommandNameLength();
        this.commandNameBuffer += this.code[codeIndex];
    }
    validateCommandCharacterAtIndex(codeIndex) {
        if ("abcdeghiklmnopqrtuwáéíóú".indexOf(this.code[codeIndex]) === -1) {
            throw new InvalidCharacterError(this.code[codeIndex], this.line, this.col);
        }
    }
    validateCommandNameLength() {
        if (this.commandNameBuffer.length === 7) {
            throw new TooLongCommandError(this.line, this.col - this.commandNameBuffer.length);
        }
    }
    parsedCharacterAtIndex(codeIndex) {
        if (this.code[codeIndex] === "\n") {
            this.line++;
            this.col = 1;
            this.isMidComment = false;
        }
        else {
            this.col++;
        }
    }
    checkLoopsBalance() {
        if (this.loopOpenCommandsCount !== this.loopCloseCommandsCount) {
            throw new UnmatchedPichulasError();
        }
    }
}
